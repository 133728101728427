<div #container
     class="video-container"
     (mousemove)="mouseMove()"
     (mouseenter)="mouseIn()"
     (touchstart)="mouseIn()"
     (mouseleave)="mouseOff()" xmlns="http://www.w3.org/1999/html">

  <video #videoAzz muted playsinline autoplay></video>

  <div class="video-disabled" *ngIf="!isReady"></div>

  <div class="video-overlay" [ngClass]="{ 'hidden': !isOverlayOn && !playerControls.isPaused}">

    <div [hidden]="showingAd" class="video-overlay-content" (dblclick)="fullScreenPressed()">

      <div class="content-top">
        <h2 *ngIf="playerControls.isFullscreen">{{ _data.name }}</h2>
      </div>

      <div class="content-middle">
        <div class="d-flex justify-content-center align-items-center">
          <button [disabled]="playerControls.volume !== 0"
                  class="btn-rounded xl mute"
                  type="button"
                  [title]="'player.volumeControl' | translate"
                  webTooltip data-bs-trigger="hover" data-bs-placement="top"
                  (click)="mutePressed()"
                  (dblclick)="onButtonDblClick($event)">
            <i class="bi bi-volume-mute"></i>
          </button>
        </div>
        <div class="d-flex justify-content-center align-items-center buttons">
          <button class="btn-rounded"
                  type="button"
                  [title]="'player.backMove' | translate"
                  webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
                  (click)="stepBackPressed()"
                  (dblclick)="onButtonDblClick($event)">
            <i class="bi bi-arrow-counterclockwise"></i>
          </button>

          <button class="btn-rounded xl"
                  type="button"
                  [title]="'player.togglePlayPause' | translate"
                  webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
                  (click)="playPressed()"
                  (dblclick)="onButtonDblClick($event)">
            <i [class]="playerControls.isPaused ? 'bi bi-play-fill' : 'bi bi-pause-fill'"></i>
          </button>

          <button class="btn-rounded"
                  type="button"
                  [title]="'player.forwardMove' | translate"
                  webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
                  (click)="stepForthPressed()"
                  (dblclick)="onButtonDblClick($event)"
                  [disabled]="_mode === 'live' && offsetCurrent === 0">
            <i class="bi bi-arrow-clockwise"></i>
          </button>
        </div>

      </div>

      <div class="content-bottom"
           (mouseover)="isOnOverlayBottom = true"
           (mouseout)="isOnOverlayBottom = false">

        <div class="time">
          <span>{{ currentTimeFormatted }}</span>
          <span *ngIf="playerControls.isLive && (_mode === 'live') && !playerControls.liveFinished">
          <i class="bi bi-circle-fill"></i>
            {{ 'player.live' | translate }}
          </span>
          <span *ngIf="playerControls.liveFinished">{{ 'player.finished' | translate }}</span>
        </div>

        <input type="range"
               [(ngModel)]="progressValue"
               (change)="changeProgress()"
               step="0.5"
               min="0"
               max="100">

        <div class="buttons-container">
          <i class="bi bi-skip-start-fill"
             [title]="'player.goToBeginning' | translate"
             webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
             (click)="goToStart()"
             (dblclick)="onButtonDblClick($event)">
          </i>

          <span *ngIf="!playerControls.isLive && (_mode === 'live') && !playerControls.liveFinished"
             class="text-i go-to-live"
             [title]="'player.goToLiveButton' | translate"
             webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
             (click)="goToLive()"
             (dblclick)="onButtonDblClick($event)">{{ 'player.goToLiveButton' | translate }}
          </span>

          <!-- <span *ngIf="_mode === 'live' && !playerControls.liveFinished" -->
          <span *ngIf="false"
                (click)="onClickLowLatency()"
                [title]="lowLatency ? ('player.lowLatencyEnabled' | translate): 'player.lowLatencyDisabled' | translate"
                webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
                [ngStyle]="lowLatency ? {color: 'red'}: {color: 'white'}">{{ 'player.lowLatency' | translate }}
            </span>

          <i [title]="'player.volumeControl' | translate"
              webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
             (click)="mutePressed()"
             (dblclick)="onButtonDblClick($event)"
             (mouseenter)="showVolumeControls()"
             [class]="(playerControls.volume === 0) ? 'bi bi-volume-mute' : (playerControls.volume > 50 ?'bi bi-volume-up':'bi bi-volume-down')">
          </i>

          <input type="range"
                 step="0.5"
                 min="0"
                 max="100"
                 [(ngModel)]="playerControls.volume"
                 [hidden]="!isVolumeControl"
                 (change)="volumePressed($event)">

          <div class="d-flex gap-4 align-items-center ms-auto">

            <i [title]="'player.fullScreenMode' | translate"
                webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
               (click)="fullScreenPressed()"
               (dblclick)="onButtonDblClick($event)"
               [class]="playerControls.isFullscreen ? 'bi bi-fullscreen-exit' : 'bi bi-fullscreen'">
            </i>

            <i class="bi bi-pip"
               [title]="'player.pictureInPictureMode' | translate"
               webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
               (click)="pipScreenPressed()"
               (dblclick)="onButtonDblClick($event)">
            </i>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #modalPlayer class="modal fade" id="modalPlayer" tabindex="-1"
  aria-labelledby="modalPlayerLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalPlayerLabel">{{ this.displayModalTitle | translate }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body d-flex flex-column gap-2 align-items-center">
        <ng-container *ngIf="this.displayModalPage === 'passwordPrompt'">
          <p class="mb-0">{{ 'player.passwordProtectedMessage' | translate }}</p>
          <p class="mb-0 text-danger">{{ this.passwordMsgText | translate }}</p>
          <ng-container *ngFor="let item of itemsPasswordSettings">
            <web-settings-item-shared *ngIf="item.visible !== false"
                                [config]="item"
                                [formGroup]="settingsForm"
                                (keydown.enter)="onClickEnterPassword()">
            </web-settings-item-shared>
          </ng-container>

          <button type="button"
                  class="btn btn-primary"
                  [disabled]="!settingsForm.valid"
                  (click)="this.onClickEnterPassword()">
            {{ "general.open" | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
