import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { PlayerClass } from '../../classes/player/player.class';
import { IPlayerData } from '../../interfaces/player/player.interface';
import { AlertService } from '../../services/alert/alert.service';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormatHoursTimePipe } from '../../pipes/format-hours-time/format-hours-time.pipe';
import { HttpClient } from '@angular/common/http';
import { IAlertData } from '../../interfaces/utils/utils.interface';
import { Modal } from 'bootstrap';
import { FormsModule } from '@angular/forms';
import { SettingsItemSharedComponent } from '../settings-item-shared/settings-item-shared.component';

@Component({
  selector: 'web-player-shared',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, SettingsItemSharedComponent],
  templateUrl: './player-shared.component.html',
  styleUrl: './player-shared.component.scss',
})

export class PlayerSharedComponent extends PlayerClass implements OnInit, OnDestroy, AfterViewInit {
  protected modalPlayer: Modal | undefined;
  @ViewChild('modalPlayer') modalElement: ElementRef | undefined;
  @ViewChild('container', {read: ElementRef}) containerElement!: ElementRef<HTMLDivElement>;
  @ViewChild('videoAzz', {read: ElementRef}) videoElement!: ElementRef;
  @Input() set mode(value: 'vod' | 'live'){
    this._mode = value;
  }

  @Input() set data(value: IPlayerData){
    this._data = value;
  }
  @Input() set ready(ready: boolean) {
    this.setReady(ready);
  }
  @Output() exitPage: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(protected alertService: AlertService,
              protected override router: Router,
              protected override translate: TranslateService,
              protected override formatTime: FormatHoursTimePipe,
              @Inject(DOCUMENT) protected override document: any,
              protected override http: HttpClient) {
      super(formatTime, router, translate, document, http);
  }

  // Added HosListeners to capture escape key
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:onfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
    fullscreenMode(){
      this.checkFullscreen(document);
    }

/*
  @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);
    if (event.key === ('ArrowLeft' || 'ArrowRight') && this.isOnOverlayBottom) {

    }
    if ((event.key === 'ArrowDown' || 'ArrowUp') && this.isVolumeControl) {
      let volume;
      const ev: any = undefined;
      if (event.key === 'ArrowDown'){
        volume = (this.playerControls.volume > 0) ? this.playerControls.volume - 10 : 0;
      }
      else{
        volume = (this.playerControls.volume < 100) ? this.playerControls.volume + 10 : 100;
      }
      this.volumePressed(ev,volume);
    }
  }
*/

  ngOnInit(): void {
    console.log('[PlayerSharedComponent] -> On init');
  }

  ngOnDestroy() {
    this.destroy();
  }

  ngAfterViewInit(): void {
    this.modalPlayer = new Modal(this.modalElement?.nativeElement);
    this.init(this.containerElement, this.videoElement);
  }

  protected override displayAlert(config: IAlertData){
    this.alertService.showAlert(config);
  }

  protected override hideModal(){
    this.modalPlayer?.hide();
  }
  protected override showModal(){
    this.modalPlayer?.show();
  }
  protected override emitExitPage(value: boolean){
    this.exitPage.emit(value);
  }
  
  protected onClickEnterPassword() {
    this.setPassword(this.settingsForm.get('password')?.value);
  }
}
