import { FormControl, FormGroup } from '@angular/forms';

import { ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IEvent, IEventCategories } from '../../interfaces/events/event.interface';
import { IAvSettingSelectOption, IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { AV_PROD_OPTIONS_EVENT_AUTO_START_MODE, AV_PROD_OPTIONS_EVENT_CATEGORIES, AV_PROD_OPTIONS_EVENT_START_CONFIG_MODE, AV_PROD_OPTIONS_EVENT_START_TIME_MODE, AV_PROD_OPTIONS_EVENT_TIME_ZONE, AV_PROD_OPTIONS_OUTPUT_VIDEO_RESOLUTION } from '../../const/av-producer-options';
import { EventsService } from '../../services/events/events.service';
import { UserService } from '../../services/user/user.service';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { EVENTS } from '../../const/events.const';


export class EventSettingsClass {
  protected useSaveButton: boolean = false;
  protected eventSettingsForm: FormGroup = new FormGroup([]);
  protected isResetNeeded: boolean = false;
  protected isDirty: boolean = false;
  protected isBusy: boolean = false;
  protected initialized: boolean = false;
  protected categories: IEventCategories[] = [];
  protected videoResolutions: IAvSettingSelectOption[] = [];
  protected _mode: string = 'edit';   // Options: 'create','edit'
  protected _event: IEvent | undefined;

  protected items: IAvSettingsItemConfig[] = [
    {
      id: 'eventName',
      type: AvProdSettingsType.text,
      name: 'eventSettings.name',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'eventDescription',
      type: AvProdSettingsType.textArea,
      name: 'eventSettings.description',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'eventCategory',
      type: AvProdSettingsType.selectComboNumber,
      name: 'eventSettings.category',
      min: 0,
      max: 0,
      step: 0,
      options: AV_PROD_OPTIONS_EVENT_CATEGORIES,
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'eventVideoResolution',
      type: AvProdSettingsType.selectComboString,
      name: 'eventSettings.videoResolution',
      min: 0,
      max: 0,
      step: 0,
      options: this.videoResolutions,
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      },
      visible: false
    },
    {
      id: 'eventTimeZone',
      type: AvProdSettingsType.selectComboString,
      name: 'eventSettings.timeZone',
      min: 0,
      max: 0,
      step: 0,
      options: AV_PROD_OPTIONS_EVENT_TIME_ZONE,
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'eventStartConfigMode',
      type: AvProdSettingsType.selectComboString,
      name: 'eventSettings.startConfigMode',
      min: 0,
      max: 0,
      step: 0,
      options: AV_PROD_OPTIONS_EVENT_START_CONFIG_MODE,
      placeholder: '',
      value: 'previous',
      config: {
        mode: 'native'
      }
    },
    {
      id: 'eventStartProduction',
      type: AvProdSettingsType.selectComboNumber,
      name: 'eventSettings.startProduction',
      min: 0,
      max: 0,
      step: 0,
      options: AV_PROD_OPTIONS_EVENT_START_TIME_MODE,
      placeholder: '',
      value: 0,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'eventStartDate',
      type: AvProdSettingsType.date,
      name: 'eventSettings.startDate',
      min: 0,
      max: 0,
      step: 0,
      options: [],
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      },
      visible: false
    },
    {
      id: 'eventStartTime',
      type: AvProdSettingsType.time,
      name: 'eventSettings.startTime',
      min: 0,
      max: 0,
      step: 0,
      options: [],
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      },
      visible: false
    },
    {
      id: 'eventPreparationTime',
      type: AvProdSettingsType.selectComboNumber,
      name: 'eventSettings.preparationTime',
      min: 10,
      max: 120,
      step: 10,
      options: [],
      placeholder: '',
      value: 60,
      config: {
        mode: 'native'
      },
      visible: false
    },
    {
      id: 'eventAutoStartMode',
      type: AvProdSettingsType.selectComboNumber,
      name: 'eventSettings.autoStartMode',
      min: 0,
      max: 0,
      step: 0,
      options: AV_PROD_OPTIONS_EVENT_AUTO_START_MODE,
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      },
      visible: false
    },
    {
      id: 'eventPrivate',
      type: AvProdSettingsType.switchBoolean,
      name: 'eventSettings.private',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      info: 'eventSettings.privateInfoDescription',
      value: true,
      visible: false
    },
    {
      id: 'eventProtected',
      type: AvProdSettingsType.switchBoolean,
      name: 'eventSettings.protected',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      info: 'eventSettings.protectedInfoDescription',
      value: undefined
    },
    {
      id: 'eventPassword',
      type: AvProdSettingsType.password,
      name: 'eventSettings.password',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: undefined
    }
  ];

  constructor(protected eventsService: EventsService,
              protected users: UserService) {
    // Do nothing
  }

  protected init(): void {
    //console.log('[EventSettingsClass] init');
    //if ((this._event === undefined)&&(this._mode === 'edit')){
    //  this._event = this.eventsService.currentEvent;
    //  console.log('[EventSettingsClass] init. By default, use current event from eventsService');
    //}
    this.items.forEach((element: IAvSettingsItemConfig) => {
      this.eventSettingsForm.addControl(element.id, new FormControl());
    });
    this.initialized = true;
    this.readSettings();
  }

  protected setIsVisible(visible: boolean): void {
    if (visible) {
      this.eventSettingsForm.markAsPristine();
      this.isDirty = false;
      if (this.initialized === true){
        this.readSettings();
      }
    }
  }

  protected setMode(value: string): void {
    if (value != this._mode) {
      if ((value === 'create') || (value === 'edit')) {
        this._mode = value;
        if (this.initialized === true){
          this.readSettings();
        }
      }
    }
  }

  protected setEvent(value: IEvent): void {
    this._event = value;
    if (this.initialized === true){
      this.readSettings();
    }
  }

  protected emitEventCreated(event: IEvent): void {
    // must be overridden
  }

  protected emitSettingsChanged(event: IEvent): void {
    // must be overridden
  }

  protected emitDirty(value: boolean): void {
    // must be overridden
  }

  protected displayToast(config: IToastConfig): void {
    // must be overridden
  }

  protected async navigateToSection(section: string): Promise<boolean> {
    return false;
  }

  protected readSettings(): void {

    // Update video resolutions
    while (this.videoResolutions.length > 0){
      this.videoResolutions.pop();
    }
    if (this.users.user.plan?.maxResolution !== undefined){
      let maxWidth: number | undefined = Number(this.users.user.plan?.maxResolution.split('x', 2)[0]);
      console.log('[EventSettingsClass] readSettings - Max width: ' + maxWidth);
      if (maxWidth !== undefined){
        for (let i:number=0; i<AV_PROD_OPTIONS_OUTPUT_VIDEO_RESOLUTION.length; i++){
          let width: number | undefined = Number(AV_PROD_OPTIONS_OUTPUT_VIDEO_RESOLUTION[i].valueStr.split('x', 2)[0]);
          if (width !== undefined){
            if (width <= maxWidth){
              this.videoResolutions.push(AV_PROD_OPTIONS_OUTPUT_VIDEO_RESOLUTION[i]);
            }
          }
        }
      }
    }

    // Update settings
    if (!this.isDirty) {
      if (this.initialized) {
        if (this._event !== undefined) {
          this.eventSettingsForm.get('eventName')?.setValue(this._event.name);
          this.eventSettingsForm.get('eventDescription')?.setValue(this._event.description);
          this.eventSettingsForm.get('eventCategory')?.setValue(this._event.categoryId);
          this.eventSettingsForm.get('eventPrivate')?.setValue(this._event.private);
          this.eventSettingsForm.get('eventProtected')?.setValue(this._event.protected);
          this.eventSettingsForm.get('eventPassword')?.setValue(this._event.protectedPassword);

          //console.log('[EventSettingsClass] readSettings - Event Data: ' + JSON.stringify(this._event));
          if (this._event?.status === EVENTS.status.scheduled){
            this.eventSettingsForm.get('eventStartProduction')?.setValue(1);
            this.eventSettingsForm.get('eventStartConfigMode')?.setValue(this._event.configMode);
            if (this._event.scheduledEventStart !== undefined){
              let ts: Date = new Date(this._event.scheduledEventStart * 1000);
              let dateStr: string = ts.getFullYear().toString().padStart(4,'0') + '-' + (ts.getMonth()+1).toString().padStart(2,'0') + '-' + ts.getDate().toString().padStart(2,'0');
              this.eventSettingsForm.get('eventStartDate')?.setValue(dateStr);
              let timeStr: string = ts.getHours().toString().padStart(2,'0') + ':' + (ts.getMinutes().toString().padStart(2,'0'));
              this.eventSettingsForm.get('eventStartTime')?.setValue(timeStr);
              if (this._event.scheduledStart !== undefined){
                this.eventSettingsForm.get('eventPreparationTime')?.setValue((this._event.scheduledEventStart - this._event.scheduledStart)/60);
              }
            }
            this.eventSettingsForm.get('eventStartConfigMode')?.setValue(this._event.configMode);
          }
          else{
            this.eventSettingsForm.get('eventStartProduction')?.setValue(0);
          }
          this.eventSettingsForm.get('eventTimeZone')?.setValue(this._event.timezone);
          this.eventSettingsForm.get('eventVideoResolution')?.setValue(this._event.maxResolution);
        } else {
          if (this.eventSettingsForm.get('eventName')?.value == null) this.eventSettingsForm.get('eventName')?.setValue('');
          if (this.eventSettingsForm.get('eventDescription')?.value == null) this.eventSettingsForm.get('eventDescription')?.setValue('');
          if (this.eventSettingsForm.get('eventCategory')?.value == null) this.eventSettingsForm.get('eventCategory')?.setValue(1);
          if (this.eventSettingsForm.get('eventPrivate')?.value == null) this.eventSettingsForm.get('eventPrivate')?.setValue(false);
          if (this.eventSettingsForm.get('eventProtected')?.value == null) this.eventSettingsForm.get('eventProtected')?.setValue(false);
          if (this.eventSettingsForm.get('eventPassword')?.value == null) this.eventSettingsForm.get('eventPassword')?.setValue('');

          if (this.eventSettingsForm.get('eventTimeZone')?.value == null) this.eventSettingsForm.get('eventTimeZone')?.setValue('');
          if (this.eventSettingsForm.get('eventStartConfigMode')?.value == null) this.eventSettingsForm.get('eventStartConfigMode')?.setValue('previous');
          if (this.eventSettingsForm.get('eventStartProcution')?.value == null) this.eventSettingsForm.get('eventStartProduction')?.setValue(0);
          if (this.eventSettingsForm.get('eventAutoStartMode')?.value == null) this.eventSettingsForm.get('eventAutoStartMode')?.setValue(0);
          if (this.eventSettingsForm.get('eventPreparationTime')?.value == null) this.eventSettingsForm.get('eventPreparationTime')?.setValue(60);
          if (this.eventSettingsForm.get('eventPreparationTime')?.value == null) this.eventSettingsForm.get('eventPreparationTime')?.setValue(60);
          if ((this.eventSettingsForm.get('eventVideoResolution')?.value == null) &&
              (this.videoResolutions.length > 0)) {
            this.eventSettingsForm.get('eventVideoResolution')?.setValue(this.videoResolutions[0].valueStr);
          }

        }
      }
      this.refreshEnabledAndVisibleControls();
      this.isResetNeeded = false;
      this.isDirty = false;
      this.emitDirty(this.isDirty);
    } else {
      this.isResetNeeded = true;
    }
  }

  protected onSettingChanged(event: IAvSettingsItemConfig): void {
    this.eventSettingsForm.markAsDirty();
    this.refreshDirtyFlags();
    this.refreshEnabledAndVisibleControls();
    if ((this.useSaveButton === false)&&(this.isDirty === true)){
      this.onSave();
    }
  }

  protected refreshEnabledAndVisibleControls(): void {
    if (this.eventSettingsForm.get('eventProtected')?.value === true) {
      this.updateItemVisibility('eventPassword', true);
    } else {
      this.updateItemVisibility('eventPassword', false);
    }

    // Temporarily disabled fields
    this.updateItemVisibility('eventAutoStartMode', false);
    this.updateItemVisibility('eventTimeZone', false);

    if (this._mode === 'create'){
      if (this.eventSettingsForm.get('eventStartProduction')?.value === 1) {
        this.updateItemVisibility('eventStartDate', true);
        this.updateItemVisibility('eventStartTime', true);
        this.updateItemVisibility('eventPreparationTime', true);
        //this.updateItemVisibility('eventAutoStartMode', true);
      }
      else {
        this.updateItemVisibility('eventStartDate', false);
        this.updateItemVisibility('eventStartTime', false);
        this.updateItemVisibility('eventPreparationTime', false);
        //this.updateItemVisibility('eventAutoStartMode', false);
      }
      this.updateItemVisibility('eventStartProduction', true);
      this.updateItemVisibility('eventStartConfigMode', true);
      if (this.videoResolutions.length > 1) {
        this.updateItemVisibility('eventVideoResolution', true);
      }
      else {
        this.updateItemVisibility('eventVideoResolution', false);
      }
      //this.updateItemVisibility('eventTimeZone', true);
    }
    else {
      //this.updateItemVisibility('eventTimeZone', false);
      this.updateItemVisibility('eventStartDate', false);
      this.updateItemVisibility('eventStartTime', false);
      this.updateItemVisibility('eventPreparationTime', false);
      this.updateItemVisibility('eventStartProduction', false);
      this.updateItemVisibility('eventStartConfigMode', false);
      this.updateItemVisibility('eventVideoResolution', false);

      if (this._event?.status === EVENTS.status.scheduled){
        this.useSaveButton = true;
        this.updateItemVisibility('eventStartDate', true);
        this.updateItemVisibility('eventStartTime', true);
        this.updateItemVisibility('eventPreparationTime', true);
        this.updateItemVisibility('eventStartProduction', true);
        this.updateItemVisibility('eventStartConfigMode', true);
        this.updateItemVisibility('eventVideoResolution', true);
      }
      else{
        this.useSaveButton = false;
      }
    }

  }

  protected updateItemVisibility(id: string, visible: boolean): void {
    const ITEM: IAvSettingsItemConfig | undefined = this.items.find((element: IAvSettingsItemConfig) => (element.id === id));
    if (ITEM !== undefined) {
      ITEM.visible = visible;
    }
  }

  protected refreshDirtyFlags(): void {
    this.isDirty = this.eventSettingsForm.dirty;
    if (this.isDirty) {
      this.isResetNeeded = true;
    }
    this.emitDirty(this.isDirty);
  }

  protected onSave(): void {
    if (this._mode === 'edit') {
      this.isBusy = true;
      if (this._event?.id !== undefined) {
        let eventStart: number = 0;
        let serverStart: number = 0;
        let schedulerError: boolean = false;
        if (this._event.status === EVENTS.status.scheduled){
          // Now or Scheduled
          schedulerError = true;
          if (this.eventSettingsForm.get('eventStartProduction')?.value === 1){
            console.log('[EventSettingsClass] onSave SCHEDULED');
            // Scheduled event
            let dateStr: string | null = this.eventSettingsForm.get('eventStartDate')?.value;
            if (dateStr !== null){
              let timeStr: string | null = this.eventSettingsForm.get('eventStartTime')?.value;
              if (timeStr !== null){
                //let dateTimeStr: string = dateStr + ' ' + timeStr + '.000';
                let dateTimeStr: string = dateStr + ' ' + timeStr;
                eventStart = new Date(dateTimeStr).getTime() / 1000;
                // Default 60 min
                serverStart = eventStart - (60 * 60);
                console.log('[EventSettingsClass] onSave Event Start TS: ' + dateTimeStr);
                let prepTimeStr: string | null = this.eventSettingsForm.get('eventPreparationTime')?.value;
                if (prepTimeStr !== null){
                  serverStart = eventStart - (Number(prepTimeStr) * 60);
                }
                const NOW: number = Date.now() / 1000;
                if (eventStart > NOW){
                  schedulerError = false;
                }
                else{
                  schedulerError = false;
                  this.displayToast({
                    options: {
                      autohide: false,
                      placement: ToastPlacement.middleCenter
                    },
                    data: {
                      closeButtonHeader: true,
                      status: ToastStatus.error,
                      title: 'eventSettings.invalidFields',
                      text: 'eventSettings.invalidScheduledTime',
                      alignText: 'text-center',
                      buttons: []
                    },
                  });        
                }
              }
            }
          }
          else{
            schedulerError = false;
          }
        }

        // Edit event configuration
        if ((schedulerError === false)&&(this.validateForm())) {
          const EVENT: IEvent = {
            id: this._event?.id,
            name: this.eventSettingsForm.get('eventName')?.value,
            description: this.eventSettingsForm.get('eventDescription')?.value,
            categoryId: this.eventSettingsForm.get('eventCategory')?.value,
            private: this.eventSettingsForm.get('eventPrivate')?.value,
            protected: this.eventSettingsForm.get('eventProtected')?.value
          };
          if (this.eventSettingsForm.get('eventPassword')?.value != '') {
            EVENT.protectedPassword = this.eventSettingsForm.get('eventPassword')?.value;
          }
          if (this._event?.producerToken !== undefined) {
            EVENT.producerToken = this._event?.producerToken;
          }

          if (this._event.status === EVENTS.status.scheduled){

            if (this.videoResolutions.length > 1){
              EVENT.maxResolution = this.eventSettingsForm.get('eventVideoResolution')?.value;
            }
            EVENT.configMode = this.eventSettingsForm.get('eventStartConfigMode')?.value;
    
            if ((EVENT.timezone === '')||
                (EVENT.timezone === undefined)||
                (EVENT.timezone === null)){
              EVENT.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            }
    
            if (serverStart !== 0){
              EVENT.scheduledStart = serverStart;
            }
            if (eventStart !== 0){
              EVENT.scheduledEventStart = eventStart;
            }
          }
  
          console.log('[EventSettingsClass] Edit save: ' + JSON.stringify(EVENT));
          this.eventsService.updateEvent(EVENT)
            .subscribe((event: IEvent | null) => {
              if (event && event.id) {
                console.log('[EventSettingsClass] Event edit');
                if (this._event === undefined) {
                  this._event = event;
                } else {
                  this._event.name = event.name;
                  this._event.description = event.description;
                  this._event.categoryId = event.categoryId;
                  this._event.private = event.private;
                  this._event.protected = event.protected;
                }
                this.emitSettingsChanged(this._event);
                this.readSettings();
              } else {
                console.log('[EventSettingsClass] Error edit Event');
              }
              this.isBusy = false;
            });

          this.eventSettingsForm.markAsPristine();
          this.isDirty = false;
          this.refreshDirtyFlags();
          this.emitDirty(this.isDirty);
        } else {
          this.isBusy = false;
        }
      } else {
        this.isBusy = false;
        console.log('[EventSettingsClass] Error changing Event. Id is not defined');
      }
    }
  }

  protected onReset(): void {
    this.isBusy = true;
    this.eventSettingsForm.markAsPristine();
    this.isDirty = false;
    this.readSettings();
    this.isBusy = false;
  }

  protected validateForm(): boolean {
    let ret: boolean = true;

    if ((this.eventSettingsForm.get('eventName')?.value == null) || (this.eventSettingsForm.get('eventName')?.value == '')) ret = false;
    if ((this.eventSettingsForm.get('eventDescription')?.value == null) || (this.eventSettingsForm.get('eventDescription')?.value == '')) ret = false;
    if ((this.eventSettingsForm.get('eventCategory') == null) || (this.eventSettingsForm.get('eventCategory')?.value == null)) ret = false;
    if ((this.eventSettingsForm.get('eventPrivate') == null) || (this.eventSettingsForm.get('eventPrivate')?.value == null)) ret = false;
    if ((this.eventSettingsForm.get('eventProtected') == null) || (this.eventSettingsForm.get('eventProtected')?.value == null)) ret = false;

    if (this.eventSettingsForm.get('eventProtected')?.value == true) {
      if (this._mode == 'create') {
        if ((this.eventSettingsForm.get('eventPassword')?.value == null) || (this.eventSettingsForm.get('eventPassword')?.value == '')) ret = false;
      }
    }

    if (!ret) {
      this.displayToast({
        options: {
          autohide: false,
          placement: ToastPlacement.middleCenter
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.error,
          title: 'eventSettings.invalidFields',
          text: 'eventSettings.invalidFieldsDetail',
          alignText: 'text-center',
          buttons: []
        },
      });
    }
    return ret;
  }

  protected onCreate(): void {
    if (this._mode === 'create') {
      this.isBusy = true;

      // Now or Scheduled
      let eventStart: number = 0;
      let serverStart: number = 0;
      let schedulerError: boolean = true;
      if (this.eventSettingsForm.get('eventStartProduction')?.value === 1){
        console.log('[EventSettingsClass] onCreate SCHEDULED');
        // Scheduled event
        let dateStr: string | null = this.eventSettingsForm.get('eventStartDate')?.value;
        if (dateStr !== null){
          let timeStr: string | null = this.eventSettingsForm.get('eventStartTime')?.value;
          if (timeStr !== null){
            //let dateTimeStr: string = dateStr + ' ' + timeStr + '.000';
            let dateTimeStr: string = dateStr + ' ' + timeStr;
            eventStart = new Date(dateTimeStr).getTime() / 1000;
            // Default 60 min
            serverStart = eventStart - (60 * 60);
            console.log('[EventSettingsClass] onCreate Event Start TS: ' + dateTimeStr);
            let prepTimeStr: string | null = this.eventSettingsForm.get('eventPreparationTime')?.value;
            if (prepTimeStr !== null){
              serverStart = eventStart - (Number(prepTimeStr) * 60);
            }
            const NOW: number = Date.now() / 1000;
            if (eventStart > NOW){
              schedulerError = false;
            }
            else{
              schedulerError = true;
              this.displayToast({
                options: {
                  autohide: false,
                  placement: ToastPlacement.middleCenter
                },
                data: {
                  closeButtonHeader: true,
                  status: ToastStatus.error,
                  title: 'eventSettings.invalidFields',
                  text: 'eventSettings.invalidScheduledTime',
                  alignText: 'text-center',
                  buttons: []
                },
              });        
            }
          }
        }
      }
      else{
        schedulerError = false;
      }

      // Create new event
      if ((schedulerError === false)&&(this.validateForm())) {
        const EVENT: IEvent = {
          userId: this.users.user.id,
          organizationId: this.users.orgId,
          name: this.eventSettingsForm.get('eventName')?.value,
          description: this.eventSettingsForm.get('eventDescription')?.value,
          categoryId: this.eventSettingsForm.get('eventCategory')?.value,
          private: this.eventSettingsForm.get('eventPrivate')?.value,
          protected: this.eventSettingsForm.get('eventProtected')?.value,
          protectedPassword: this.eventSettingsForm.get('eventPassword')?.value,
          configMode: this.eventSettingsForm.get('eventStartConfigMode')?.value,
          timezone: this.eventSettingsForm.get('eventTimeZone')?.value,
        };
        if (this.videoResolutions.length > 1){
          EVENT.maxResolution = this.eventSettingsForm.get('eventVideoResolution')?.value;
        }

        if ((EVENT.timezone === '')||
            (EVENT.timezone === undefined)||
            (EVENT.timezone === null)){
          EVENT.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        if (serverStart !== 0){
          EVENT.scheduledStart = serverStart;
        }
        if (eventStart !== 0){
          EVENT.scheduledEventStart = eventStart;
        }

        console.log('[EventSettingsClass] Create: ' + JSON.stringify(EVENT));

        this.eventsService.createEvent(EVENT)
          .subscribe((event: IEvent | null) => {
            if (event && event.id) {
              console.log('[EventSettingsClass] Event created');
              this._event = event;
              this.readSettings();
              this.emitEventCreated(event);
            } else {
              let errorMsg: string = 'eventSettings.errorCreatingEventDetails';
              this.displayToast({
                options: {
                  autohide: false,
                  placement: ToastPlacement.middleCenter
                },
                data: {
                  closeButtonHeader: true,
                  status: ToastStatus.error,
                  title: 'general.error',
                  text: 'eventSettings.errorCreatingEventDetails',
                  alignText: 'text-center',
                  buttons: []
                },
              });
              console.log('[EventSettingsClass] Error creating Event');
            }
            this.isBusy = false;
          });
      } else {
        this.isBusy = false;
        console.log('[EventSettingsClass] Fields not valid');
      }
    }
  }
}
