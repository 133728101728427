<div class="p-1">
  <ng-container [formGroup]="formGroup">
    <!-- <small class="mb-1" *ngIf="configInt.info">{{configInt.info | translate}}</small> -->

    <div *ngIf="configInt.type === 'text'"
        class="form-floating">
      <input type="text"
            class="form-control"
            aria-describedby="basic-input"
            [id]="configInt.id"
            [placeholder]="configInt.placeholder"
            [formControlName]="configInt.id"
            [maxlength]="configInt.max !== 0? configInt.max : 50"
            webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
            [title]="configInt.info? (configInt.info | translate) : ''"
            (input)="onControlTouched()"
            (change)="onControlChange()"
            (focus)="onControlFocus()"
            (click)="onInputScroll(configInt.id)">
      <label [for]="configInt.id">{{ configInt.name | translate }}</label>
    </div>

    <div *ngIf="configInt.type === 'numberText'"
        class="form-floating">
      <input type="number"
            class="form-control"
            aria-describedby="basic-input"
            [id]="configInt.id"
            [placeholder]="configInt.placeholder"
            [formControlName]="configInt.id"
            [min]="configInt.min" [max]="configInt.max"
            webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
            [title]="configInt.info? (configInt.info | translate) : ''"
            (input)="onControlTouched()"
            (change)="onControlChange()"
            (focus)="onControlFocus()"
            (click)="onInputScroll(configInt.id)">
      <label [for]="configInt.id">{{ configInt.name | translate }}</label>
    </div>

    <div *ngIf="configInt.type === 'date'"
        class="form-floating">
      <input type="date"
            class="form-control"
            aria-describedby="basic-input"
            [id]="configInt.id"
            [placeholder]="configInt.placeholder"
            [formControlName]="configInt.id"
            webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
            [title]="configInt.info? (configInt.info | translate) : ''"
            (input)="onControlTouched()"
            (change)="onControlChange()"
            (focus)="onControlFocus()"
            (click)="onInputScroll(configInt.id)">
      <label [for]="configInt.id">{{ configInt.name | translate }}</label>
    </div>

    <div *ngIf="configInt.type === 'time'"
        class="form-floating">
      <input type="time"
            class="form-control"
            aria-describedby="basic-input"
            [id]="configInt.id"
            [placeholder]="configInt.placeholder"
            [formControlName]="configInt.id"
            webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
            [title]="configInt.info? (configInt.info | translate) : ''"
            (input)="onControlTouched()"
            (change)="onControlChange()"
            (focus)="onControlFocus()"
            (click)="onInputScroll(configInt.id)">
      <label [for]="configInt.id">{{ configInt.name | translate }}</label>
    </div>

    <div *ngIf="configInt.type === 'textArea'"
        class="form-floating">
      <textarea
            class="form-control"
            [id]="configInt.id"
            [placeholder]="configInt.placeholder"
            [formControlName]="configInt.id"
            [maxlength]="configInt.max !== 0? configInt.max : 255"
            webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
            [title]="configInt.info? (configInt.info | translate) : ''"
            (input)="onControlTouched()"
            (change)="onControlChange()"
            (focus)="onControlFocus()"
            (click)="onInputScroll(configInt.id)">
      </textarea>
      <label [for]="configInt.id">{{ configInt.name | translate }}</label>
    </div>

    <div *ngIf="configInt.type === 'password'"
        class="form-floating">
      <input type="password"
            class="form-control"
            [id]="configInt.id"
            [placeholder]="configInt.placeholder"
            [formControlName]="configInt.id"
            [maxlength]="configInt.max !== 0? configInt.max : 30"
            (input)="onControlTouched()"
            (change)="onControlChange()"
            (focus)="onControlFocus()"
            (click)="onInputScroll(configInt.id)">
      <label [for]="configInt.id">{{ configInt.name | translate }}</label>
    </div>

    <fieldset class="form-radio-container" *ngIf="configInt.type === 'radio'">
      <legend>{{ (configInt.title)? (configInt.title | translate) : configInt.name }}</legend>
      <div class="form-check" *ngFor="let item of configInt.options">
        <input class="form-check-input"
              type="radio"
              [name]="configInt.id"
              [formControlName]="configInt.id"
              [id]="item.valueStr"
              [value]="item.valueStr"
              [checked]="item.valueNumber === 1"
              (change)="onControlChange()"
              (focus)="onControlFocus()">
        <label class="form-check-label" [for]="item.valueStr">
          {{ item.labelTranslate | translate }}
        </label>
      </div>
    </fieldset>


    <div *ngIf="(configInt.type === 'switchBoolean' || configInt.type === 'switchNumber')"
        [ngClass]="(configInt.name !== '')? 'form-switch-container':'form-switch-container-no-label'">
  <!--     <p role="switch">
        {{ (configInt.title) ? (configInt.title | translate) : '' }}
      </p>
  -->
      <div class="form-check-reverse form-switch">
        <label *ngIf="configInt.name !== ''" class="form-check-label" [for]="configInt.id">
          {{ configInt.name | translate }}<i *ngIf="configInt.info !== undefined" class="bi bi-info-circle ms-2" webTooltip data-bs-trigger="hover" data-bs-placement="bottom" [title]="configInt.info? (configInt.info | translate) : ''"></i>
        </label>
        <input class="form-check-input"
              type="checkbox"
              role="switch"
              [name]="configInt.id"
              [id]="configInt.id"
              [min]="configInt.min" [max]="configInt.max" [step]="configInt.step"
              [placeholder]="configInt.placeholder"
              [formControlName]="configInt.id"
              (focus)="onControlFocus()"
              (change)="onControlChange()">
      </div>
    </div>


    <div *ngIf="(configInt.type === 'selectComboString')"
        class="form-floating">
      <select [formControlName]="configInt.id"
              [id]="configInt.id"
              class="form-select"
              aria-label="Select"
              [name]="configInt.id"
              webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
              [title]="configInt.info? (configInt.info | translate) : ''"
              (change)="onControlChange()"
              (focus)="onControlFocus()">
        <option *ngIf="configInt.placeholder !== ''"
              disabled selected
              value="null">
          {{ configInt.placeholder | translate }}
        </option>
        <option *ngFor="let item of configInt.options"
                [value]="item.valueStr">
          {{ item.labelTranslate }}
        </option>
      </select>
      <label [for]="configInt.id">{{ configInt.name | translate }}</label>
    </div>

    <div *ngIf="(configInt.type === 'selectComboNumber')"
        class="form-floating">
      <select [formControlName]="configInt.id"
              [id]="configInt.id"
              class="form-select"
              aria-label="Select"
              [name]="configInt.id"
              webTooltip data-bs-trigger="hover" data-bs-placement="bottom"
              [title]="configInt.info? (configInt.info | translate) : ''"
              (change)="onControlChange()"
              (focus)="onControlFocus()">
        <option *ngIf="configInt.placeholder !== ''"
              disabled selected
              [ngValue]="null">
          {{ configInt.placeholder | translate }}
        </option>
        <option *ngFor="let item of configInt.options"
                [ngValue]="item.valueNumber">
          {{ item.labelTranslate }}
        </option>
      </select>
      <label [for]="configInt.id">{{ (configInt.name | translate) }}</label>
    </div>

    <div *ngIf="configInt.type === 'numberSlider'" class="form-slider-container form-floating">
      <i class="bi bi-chevron-left slider-button-icon"
        (click)="onSliderDecrease()">
      </i>
      <input type="range" class="form-range w-9"
            [min]="configInt.min" [max]="configInt.max" [step]="configInt.step"
            [id]="configInt.id"
            [formControlName]="configInt.id"
            (change)="onControlChange()"
            (focus)="onControlFocus()">
      <i class="bi bi-chevron-right slider-button-icon"
        (click)="onSliderIncrease()">
      </i>
      <label [for]="configInt.id">{{ (configInt.name | translate) + ': ' + this.formControl.value }}</label>
    </div>

    <input *ngIf="configInt.type === 'color'"
          type="text" hidden
          [formControlName]="configInt.id"
          (focus)="onControlFocus()"
          (change)="onControlChange()"/>

  </ng-container>
  <ng-container>
    <!--
    <div *ngIf="(configInt.type === 'color')" class="color-container">
      <input *ngIf="colorText === 'custom'"
            type="color" class="form-control form-control-color"
            [(ngModel)]="colorCustom"
            (change)="onColorChange()">
      <select
        [(ngModel)]="colorText"
        (change)="onColorChange()"
        (focus)="onControlFocus()">
        <option *ngFor="let item of configInt.options"
                [value]="item.valueStr">
          {{ item.labelTranslate }}
        </option>
      </select>
    </div>
  -->
    <div *ngIf="(configInt.type === 'color')"
        class="form-floating d-flex">

      <select
              [(ngModel)]="colorText"
              class="form-select"
              aria-label="Select"
              (change)="onColorChange()"
              (focus)="onControlFocus()">
        <option *ngFor="let item of configInt.options"
                [value]="item.valueStr">
          {{ item.labelTranslate }}
        </option>
      </select>
      <label [for]="configInt.id">{{ configInt.name | translate }}</label>
      <input *ngIf="true"
        type="color" class="form-control form-control-color"
        [(ngModel)]="colorCustom"
        (change)="onColorChangeCustom()">

    </div>

  </ng-container>
</div>