// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const ENV_HQSPORT_COMMON = {
  production: false,
  scheme: 'https://',
  bundleId: 'com.hqsport.com',
  domain: 'dev.hqsport.com',
  api: 'https://dev-apihq.azzulei.com/v1',
  web: 'https://dev.hqsport.com/',
  downloadUrl: 'https://dev-download.hqsport.com/',
  uploadUrl: 'https://dev-upload.hqsport.com/',
  playlist: 'dev-play.azzulei.tv',

  oAuthGoogle: {
    authorizationBaseUrl: 'https://accounts.google.com/o/oauth2/auth',
    accessTokenEndpoint: 'https://www.googleapis.com/oauth2/v4/token',
    scope: 'email profile',
    resourceUrl: 'https://www.googleapis.com/userinfo/v2/me',
    web: {
      responseType: 'token', // implicit flow
      accessTokenEndpoint: '', // clear the tokenEndpoint as we know that implicit flow gets the accessToken from the authorizationRequest
      //redirectUrl: 'https://hqsport.com',
      windowOptions: 'height=600,left=0,top=0'
    }
  },

  oAuthMicrosoft: {
    authority: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
    scopes: 'https://graph.microsoft.com/User.Read',
    graphUrl: 'https://graph.microsoft.com/v1.0/me/',
    accessTokenEndPoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
    windowOptions: 'height=600,left=0,top=0',
    tenantId: '2828acdb-d741-4207-93c4-c687914d8b13',
    redirectUrl: `msauth://com.hqsport.com/`, // See Azure Portal -> Authentication -> Android Configuration "Redirect URI",
    hash: 'x0OdBW4qczYMV4tYBaQEVQT9NUA=',
  },
  appleSsoClientId: {
    bundle: 'com.hqsport.com'
  },
  enableHotjarTracking: true,

 /* googleSsoClientId: {
    web: '492270698470-dnuhqj9d1lbs1tisirdlniuh81f99i82.apps.googleusercontent.com',
    android: [
      '492270698470-accom7ma27e2d6fucnse0cst9oo1ln63.apps.googleusercontent.com',
      '492270698470-fj1ofb9qmannojrqcgivthrpo41doucj.apps.googleusercontent.com'
    ],
    ios: '492270698470-oars8ig19310te095muq947pvrm9ti71.apps.googleusercontent.com',
    iosURLScheme: 'com.googleusercontent.apps.492270698470-oars8ig19310te095muq947pvrm9ti71'
  },
  microsoftSsoClientId: {
    clientId: '0f9dd6a9-a882-4902-b8db-865b70ff2980',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '',
  },*/

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
