import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'web-profile-banner-shared',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './profile-banner-shared.component.html',
  styleUrl: './profile-banner-shared.component.scss',
})
export class ProfileBannerSharedComponent {

  @Input() layoutMode: string = '';
  @Input() name: string | undefined;
  @Input() bannerImageSrc: string | undefined;
  @Input() profileImageSrc: string | undefined;

  constructor(){
  }
}
